import React, { useRef } from 'react'
import Swal from 'sweetalert2'
import { createBrowserRouter, RouterProvider, useNavigate } from "react-router-dom";
import emailjs, { init } from "@emailjs/browser";
import logo from '../assets/images/the-fan-club.svg';
function Contact() {

  const navigate = useNavigate();

  init("YhBTm1Y96843_eGsX");
    const form = useRef();

    const handleSubmit = (e) => {
      e.preventDefault();
      emailjs.sendForm("service_31rnqb9", "template_oh92ehk", form.current, "YhBTm1Y96843_eGsX").then(
        (result) => {
          navigate("/Confirmation")
        },
        (error) => {
          Swal.fire({
            title: 'Error!',
            text: "Votre message n'a pas été envoyé",
            icon: 'error',
          })
        }
      );
    };


  return (
    <div className="container page-contact">
      <header>
        <div className="logo">
          <a href="/"><img src={logo} alt="The Fan Club" /></a>
        </div>
      </header>
      <div className="content-form">
        <div className="column">
          <h1>Collaborer avec <br /><span>THE FAN CLUB.</span></h1>
          <p>Gorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. </p>
          <div className="bloc-adresse">
            <div className="adresse">
              <h5>ADRESSE</h5>
              <p>Corem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
            <div className="adresse">
              <h5>CONTACT</h5>
              <p>hello@thefan.club <br />+41 83 000 00 __</p>
            </div>
          </div>
        </div>
        <div className="column-form">
          <form ref={form} onSubmit={handleSubmit}>
            <div className="form-text half">
              <input type="text" name="username" placeholder="Nom" />
            </div>
            <div className="form-text half">
              <input type="text" name="firstname" placeholder="Prénom" />
            </div>
            <div className="form-text">
              <input type="text" name="tel" placeholder="Numéro de téléphone" />
            </div>
            <div className="form-text">
              <input type="email" name="email" placeholder="Email" required />
            </div>
            <div className="form-text">
              <textarea name="message" placeholder="Message"></textarea>
            </div>
            <div className="form-button btn-request">
              <input type="submit" name="" value="Envoyez" />
            </div>
          </form>
        </div>
      </div>
      <div className="rond rond1"></div>
      <div className="rond rond2"></div>
      <div className="rond rond3"></div>
      <div className="rond rond4"></div>
      <div className="rond rond5"></div>
      <div className="rond rond6"></div>
      <div className="rond rond7"></div>
      <div className="rond rond8"></div>
      <footer>
        <div className="container footer-bottom">
          <p>© 2024 The Fan Club. Tous droits réservés.</p>
          <div className="social">
            <a href="#" className="facebook">Facebook</a>
            <a href="#" className="twitter">Twitter</a>
            <a href="#" className="linkedin">linkedin</a>
            <a href="#" className="youtube">youtube</a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Contact;
