import React from 'react'
import logo from '../assets/images/the-fan-club.svg';
function Header() {
  return (
    <div className="container">
      <header>
        <div className="logo">
          <img src={logo} alt="The Fan Club" />
        </div>
        <div className="link-contact">
          <a href="/Contact"><span>Contactez-nous</span></a>
        </div>
      </header>
      <div className="content-top">
        <h1>THE-<span>fan</span>.club transformer votre audience en empire</h1>
        <p>Avec THE-fan.club, chaque follower devient une opportunité. Découvrez comment.</p>
        <div className="btn-play mt-100">
          <a href="#services" className="btn ancre"><span>Découvrez Nos Services</span></a>
          <span className="play">Play</span>
        </div>
      </div>
      <div className="rond rond1"></div>
      <div className="rond rond2"></div>
      <div className="rond rond3"></div>
      <div className="rond rond4"></div>
      <div className="rond rond5"></div>
      <div className="rond rond6"></div>
      <div className="rond rond7"></div>
      <div className="rond rond8"></div>
    </div>
  );
}

export default Header;
