import React from 'react'
import un from '../assets/images/1.svg';
import deux from '../assets/images/2.svg';
import trois from '../assets/images/3.svg';
import quatre from '../assets/images/4.svg';
import cinq from '../assets/images/5.svg';
import six from '../assets/images/6.svg';
function TheFan() {
  return (
    <div className="container container-fan">
      <div className="item item1">
        <div className="items">
          <h2><img src={un} alt="The Fan Club" /><span>Expertise Unique</span></h2>
          <p>Une profonde compréhension du paysage numérique des célébrités.</p>
        </div>
        <div className="items">
          <h2><img src={deux} alt="The Fan Club" /><span>Solutions Sur Mesure</span></h2>
          <p>Des applications et stratégies conçues spécifiquement pour votre marque.</p>
        </div>
        <div className="items">
          <h2><img src={trois} alt="The Fan Club" /><span>Engagement Authentique</span></h2>
          <p>Renforcez les liens avec vos fans grâce à des interactions significatives.</p>
        </div>
      </div>

      <div className="item item2">
        <div className="items">
          <h2><img src={quatre} alt="The Fan Club" /><span>Monétisation Innovante</span></h2>
          <p>Des stratégies créatives pour maximiser vos revenus.</p>
        </div>
        <div className="items items2">
          <h2><img src={cinq} alt="The Fan Club" /><span>Accompagnement Continu</span></h2>
          <p>Un partenariat à chaque étape de votre parcours numérique.</p>
        </div>
        <div className="items">
          <h2><img src={six} alt="The Fan Club" /><span>Résultats Mesurables</span></h2>
          <p>Des objectifs clairs, des mesures précises, une réussite partagée.</p>
        </div>
      </div>
      <div className="bloc-fan">
        <p>The Fan <br />.club</p>
      </div>
    </div>
  );
}

export default TheFan;
