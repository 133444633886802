import React, { Fragment } from 'react'
import Header from './Component/Header'
import QuiSommesNous from './Component/QuiSommesNous'
import TheFan from './Component/TheFan'
import Services from './Component/Services'
import Slider from './Component/Slider'
import Footer from './Component/Footer'

function App() {
  return (
    <Fragment>
      <Header/>
      <QuiSommesNous/>
      <TheFan/>
      <Services/>
      <Footer/>
    </Fragment>
  );
}

export default App;
