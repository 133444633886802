import React from 'react'
function QuiSommesNous() {
  return (
    <div className="container">
      <h2>Qui Sommes-Nous ?</h2>
      <h3>transformer votre présence en ligne en une expérience inoubliable pour vos fans et une source de revenus pérenne pour vous.</h3>
      <p>THE-fan.club est à l'avant-garde de la monétisation de l'audience pour les stars des réseaux sociaux. Spécialistes en création d'applications mobiles personnalisées, nous transformons votre influence en revenus tangibles, tout en renforçant le lien avec votre communauté.</p>
    </div>
  );
}

export default QuiSommesNous;
