import React from 'react'
import logo from '../assets/images/the-fan-club.svg';
function Footer() {
  return (
      <footer>
        <div className="container">
          <h4>Collaborer avec <br /><span>THE FAN CLUB.</span></h4>
          <p>Pour transformer votre audience en un empire numérique, The Fan Club vous offre une expertise unique en monétisation et engagement de communauté. Nous sommes prêts à créer avec vous des applications mobiles personnalisées, des stratégies de contenu exclusif, et bien plus. Chaque follower est une opportunité; découvrez comment maximiser votre présence en ligne avec notre soutien. Contactez-nous à
  <a href="mailto:hello@thefan.club"> hello@thefan.club</a> pour explorer une collaboration qui transcendera votre marque. Ensemble, faisons de votre vision une réalité.</p>
          <a href="/Contact" className="btn"><span>Contactez-nous</span></a>
          <div className="bloc-adresse">
            <div className="adresse">
              <h5>ADRESSE</h5>
              <p>PULSE.digital <br />Route de la Chaux 4C <br />1030 Bussigny</p>
            </div>
            <div className="adresse">
              <h5>CONTACT</h5>
              <p><a href="mailto:hello@pulse.digital">hello@pulse.digital</a><br />+41 21 546 20 21</p>
            </div>
          </div>
        </div>
        <div className="container footer-bottom">
          <p>© 2024 The Fan Club. Tous droits réservés.</p>
          <div className="social">
            <a href="#" className="facebook">Facebook</a>
            <a href="#" className="twitter">Twitter</a>
            <a href="#" className="linkedin">linkedin</a>
            <a href="#" className="youtube">youtube</a>
          </div>
        </div>
      </footer>
  );
}

export default Footer;
