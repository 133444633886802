import React, { useRef } from 'react'
import logo from '../assets/images/the-fan-club.svg';

function Confirmation() {

  return (
    <div className="container page-contact page-confirmation">
      <header>
        <div className="logo">
          <a href="/"><img src={logo} alt="The Fan Club" /></a>
        </div>
        <div className="link-contact">
          <a href="/Contact"><span>Contact Us</span></a>
        </div>
      </header>
      <div className="content-form">
          <h2>MERCI POUR VOTRE DEMANDE</h2>
          <p>Votre message a bien été envoyé. Nous vous répondrons dans les plus brefs délais.</p>
          <a href="/" className="btn"><span>Retour</span></a>
      </div>
      <div className="rond rond1"></div>
      <div className="rond rond2"></div>
      <div className="rond rond3"></div>
      <div className="rond rond4"></div>
      <div className="rond rond5"></div>
      <div className="rond rond6"></div>
      <div className="rond rond7"></div>
      <div className="rond rond8"></div>
      <footer>
        <div className="container footer-bottom">
          <p>© 2024 The Fan Club. Tous droits réservés.</p>
          <div className="social">
            <a href="#" className="facebook">Facebook</a>
            <a href="#" className="twitter">Twitter</a>
            <a href="#" className="linkedin">linkedin</a>
            <a href="#" className="youtube">youtube</a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Confirmation;
