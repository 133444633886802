import React from 'react'
import app from '../assets/images/icon-app.svg';
import monetisation from '../assets/images/icon-monetisation.svg';
import contenu from '../assets/images/icon-contenu.svg';
import management from '../assets/images/icon-management.svg';
function Services() {
  return (
    <div className="container container-services" id="services">
      <div className="item">
        <img src={app} alt="The Fan Club" />
        <h2>Création d'Applications Mobiles Sur Mesure </h2>
        <p>Votre marque, votre vision, transformées en une application mobile immersive. Engagez vos fans avec du contenu exclusif, des événements virtuels, et des expériences uniques, le tout sous votre contrôle total.</p>
      </div>

      <div className="item">
        <img src={monetisation} alt="The Fan Club" />
        <h2>Consulting en Monétisation</h2>
        <p>Augmentez vos revenus grâce à notre expertise en stratégies de monétisation innovantes. De la publicité ciblée aux abonnements exclusifs, nous vous aidons à tirer le meilleur parti de chaque follower.</p>
      </div>

      <div className="item">
        <img src={contenu} alt="The Fan Club" />
        <h2>Gestion de Contenu Exclusif </h2>
        <p>Créez et partagez du contenu qui captive et conserve. Que ce soit pour des coulisses, des tutoriels, ou des moments personnels, nous vous aidons à raconter votre histoire de manière authentique et engageante.</p>
      </div>

      <div className="item">
        <img src={management} alt="The Fan Club" />
        <h2>Community Management </h2>
        <p>Laissez-nous gérer votre communauté pour renforcer l'engagement et la fidélité de vos fans. Notre approche stratégique assure une croissance soutenue et une interaction significative avec votre audience.</p>
      </div>
    </div>
  );
}

export default Services;
